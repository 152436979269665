.entryStatus {
  color: white;
  font-size: 0.8rem;
  font-weight: bold;
  font-style: normal;
  padding: 0 .25rem;
}

.statusBlocked {
  background-color: green;
}

.statusRequested {
  background-color: blue;
}

.statusAccepted {
  background-color: green;
}

.statusRejected {
  background-color: red;
}

.statusManuell {
  background-color: purple;
}
