body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f7fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h3 {
  /* See also: createMuiTheme */
  font-size: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.8px;
}

/* Classes */

.week-header {
  font-weight: 500;
  font-size: 16px;
  padding-left: 4px;
  height: 22px;
  letter-spacing: -1px;
  border-bottom: 1px solid #ddd;
}

.day-header {
  padding-top: 3px;
  height: 22px;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
}

tr[data-time] {
  transform: translate(0px, -10px);
}

.fc-time-grid-event {
  transform: translate(0px, 10px);
}

.holiday .day-header {
  color: #dedede;
}

.MuiFormLabel-root {
  font-size: 0.875rem; /* 14px */
  font-weight: 500;
  text-transform: uppercase;
  color: #222;
  margin-bottom: 1rem;
}

.MuiChip-deleteIcon {
  width: 25px;
  height: 25px;
}

.MuiChip-deleteIconOutlinedColorSecondary,
.MuiChip-deleteIconOutlinedColorSecondary:active,
.MuiChip-deleteIconOutlinedColorSecondary:hover {
  color: #222;
}

.fc-button,
.fc-button-primary,
.fc-button-primary:not(:disabled).fc-button-active,
.fc-button-group > .fc-button:not(:first-child),
.fc-button-group > .fc-button:not(:last-child) {
  background-color: #2072e6;
  border-width: 0px;
  border-radius: 30px;
  min-width: 30px;
  height: 30px;
  padding: 0;
  font-size: 1.1em;
  font-weight: bold;
}

.fc-toolbar > * > :not(:first-child) {
}

.todayButton {
  color: #2072e6;
  font-weight: bold;
  margin: 0 1.5em 0 2.25em !important;
  cursor: pointer;
}

.timeframe-select-button {
  border-bottom: 2px solid #cccccc;
  padding: 0.7em;
  color: #666666;
  cursor: pointer;
  font-weight: bold;
  font-size: 1.2em;
}

.timeframe-select-button.active {
  border-bottom: 2px solid #2072e6;
  color: #2072e6;
}

.fc-toolbar h2 {
  font-weight: normal;
  font-size: 2.6em;
  margin: 0.3em;
}

.fc th.fc-widget-header,
.fc th.fc-widget-header .holiday {
  border-style: none;
  background-color: transparent;
}

.fc th.fc-axis,
.fc td.fc-axis,
.fc-body > tr > td.fc-widget-content,
.fc-unthemed thead,
.fc td.fc-head-container {
  border-style: none;
}

.MuiGrid-container {
  background-color: #fff;
  margin-top: 2em;
}

.fc th.fc-day-header {
  padding-bottom: 0em;
}
